// SignInPrompt.js
import React from 'react';
import IdeaCard from './IdeaCard';

const SignInPrompt = ({ signInWithGoogle }) => {
  const generatePlaceholderIdeas = (count) => {
    const placeholderNames = [
      "Nice try, champ!",
      "Almost insightful!",
      "Keep at it, tiger.",
      "Not quite there yet!",
      "Dream big, buddy!",
      "Getting warmer!",
      "Oh, you're close!",
      "Better luck next time!",
      "Keep digging, detective!",
      "On the right track!",
      "Good hustle, pal!",
      "Almost genius!",
      "Creative... kinda.",
      "Solid attempt!",
      "Close, but not quite!",
      "Nice one, Sherlock!",
      "A+ for effort!",
      "Imaginative, almost!",
      "So close, yet so far!",
      "Good try, big dog.",
    ];

    return Array.from({ length: count }, (_, i) => ({
      id: `placeholder-${i}`,
      idea: placeholderNames[Math.floor(Math.random() * placeholderNames.length)],
      context_summary: 'Sign in to view detailed insights!',
      difficulty: Math.floor(Math.random() * 10) + 1,
      category: ':)',
      timestamp: '00:20:00',
      video_url: '',
      channel_name: "Hidden Channel",
      channel_image: '',
    }));
  };

  const placeholderIdeas = generatePlaceholderIdeas(6);

  return (
    <div className="relative">
      {/* Render the placeholder ideas with a blur effect */}
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 opacity-20 filter blur-sm">
        {placeholderIdeas.map((idea) => (
          <IdeaCard key={idea.id} idea={idea} />
        ))}
      </div>

      {/* Sign In Prompt Overlay */}
      <div className="absolute inset-0 flex items-start justify-center">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-lg font-bold mb-4">Sign in to get more specific insights!</h2>
          <button onClick={() => signInWithGoogle()} className="btn btn-primary">
            Sign In with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignInPrompt;
