import React, { useEffect, useState } from 'react';
import { supabase } from './supabaseClient';
import IdeaCard from './IdeaCard';
import { useAuth } from './AuthContext';
import SignInPrompt from './SignInPrompt';

// Helper function to shuffle an array
const shuffleArray = (array) => {
  return array
    .map((item) => ({ ...item, sortKey: Math.random() }))
    .sort((a, b) => a.sortKey - b.sortKey)
    .map(({ sortKey, ...rest }) => rest);
};

const IdeasList = ({ channel }) => {
  const { session, signInWithGoogle } = useAuth();
  const [categories, setCategories] = useState([]);
  const [ideas, setIdeas] = useState([]);
  const [filteredIdeas, setFilteredIdeas] = useState([]);
  const [shuffledIdeas, setShuffledIdeas] = useState([]); // Holds the shuffled filtered ideas
  const [activeCategory, setActiveCategory] = useState('All');
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [sortAscending, setSortAscending] = useState(null);
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const [ideasToShow, setIdeasToShow] = useState(21); // Number of ideas currently displayed

  useEffect(() => {
    if (!session && ideasToShow > 21) {
      setShowSignInPrompt(true);
    }
  }, [session, ideasToShow]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data, error } = await supabase.from('ideas').select('category');
        if (error) throw error;
        let uniqueCategories = ['All', ...new Set(data.map(item => item.category))];
        uniqueCategories = uniqueCategories.filter(cat => cat !== 'Other');
        uniqueCategories.push('Other');
        setCategories(uniqueCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setCategories(['All']);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchIdeas = async () => {
      const { data, error } = await supabase.from('ideas').select(`
        id, idea, context_summary, category, timestamp, difficulty,
        podcast_analyses ( video_url, podcast_title, channels ( name, profile_picture_url, id ) )
      `);

      if (error) {
        console.error('Error fetching ideas:', error);
      } else {
        const formattedIdeas = data.map(idea => ({
          ...idea,
          video_url: idea.podcast_analyses.video_url,
          podcast_title: idea.podcast_analyses.podcast_title,
          channel_name: idea.podcast_analyses.channels.name,
          channel_image: idea.podcast_analyses.channels.profile_picture_url,
          channel_id: idea.podcast_analyses.channels.id,
        }));

        setIdeas(formattedIdeas);

        // Handle initial filtering by channel if passed as a prop
        if (channel) {
          const filteredByChannel = formattedIdeas.filter(
            (idea) => idea.channel_name.toLowerCase() === channel.toLowerCase()
          );
          setSelectedChannels(
            filteredByChannel.map((idea) => idea.channel_id)
          );
          setFilteredIdeas(filteredByChannel);
        } else {
          setFilteredIdeas(formattedIdeas);
        }

        // Extract unique channels for filtering
        const uniqueChannels = Array.from(
          new Map(
            formattedIdeas.map((idea) => [
              idea.channel_id,
              {
                id: idea.channel_id,
                name: idea.channel_name,
                image: idea.channel_image,
              },
            ])
          ).values()
        );
        setChannels(uniqueChannels);
      }
    };

    fetchIdeas();
  }, [channel]);

  useEffect(() => {
    const applyFilters = () => {
      if (showSignInPrompt && !session) return; // Do not apply filters if sign-in is required

      let filtered = ideas;

      if (activeCategory !== 'All') {
        filtered = filtered.filter((idea) => idea.category === activeCategory);
      }

      if (selectedChannels.length > 0) {
        filtered = filtered.filter((idea) => selectedChannels.includes(idea.channel_id));
      }

      // Shuffle once and save the shuffled result
      const shuffled = shuffleArray(filtered);
      setShuffledIdeas(shuffled);
      setFilteredIdeas(filtered); // Keep original filtered list (not shuffled) if needed
    };

    applyFilters();
  }, [ideas, activeCategory, selectedChannels, showSignInPrompt, session]);

  const handleCategoryChange = (category) => {
    if (!session) {
      setShowSignInPrompt(true);
      return;
    }

    setActiveCategory(category);
  };

  const toggleChannelSelection = (channelId) => {
    if (!session) {
      setShowSignInPrompt(true);
      return;
    }

    const updatedSelection = selectedChannels.includes(channelId)
      ? selectedChannels.filter((id) => id !== channelId)
      : [...selectedChannels, channelId];

    setSelectedChannels(updatedSelection);
  };

  const handleSortByDifficulty = () => {
    const sortedIdeas = [...shuffledIdeas].sort((a, b) =>
      sortAscending ? a.difficulty - b.difficulty : b.difficulty - a.difficulty
    );
    setShuffledIdeas(sortedIdeas);
    setSortAscending(!sortAscending);
  };

  const loadMoreIdeas = () => {
    setIdeasToShow((prev) => prev + 21); // Load 21 more ideas
  };

  return (
    <div className="container mx-auto p-4">
      <div className="navbar bg-base-200 rounded-lg p-4 mb-6 overflow-x-auto whitespace-nowrap">
        {categories.map((category) => (
          <button
            key={category}
            className={`btn mr-1 btn-ghost ${activeCategory === category ? 'btn-active' : ''}`}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="flex space-x-4 mb-6 overflow-x-auto">
        {channels.map((channel) => (
          <button
            key={channel.id}
            className={`btn btn-outline ${selectedChannels.includes(channel.id) ? 'btn-active' : ''}`}
            onClick={() => toggleChannelSelection(channel.id)}
          >
            <img
              src={channel.image}
              alt={channel.name}
              className="w-6 h-6 rounded-full mr-2"
            />
            {channel.name}
          </button>
        ))}
      </div>

      <div className="flex items-center mb-6">
        <button
          className={`btn ${sortAscending === null ? 'btn-outline' : 'btn-primary'}`}
          onClick={handleSortByDifficulty}
        >
          Sort by Difficulty {sortAscending === true ? '↑' : sortAscending === false ? '↓' : ''}
        </button>
        <span className="text-gray-600 text-sm ml-4">
          {filteredIdeas.length} {filteredIdeas.length === 1 ? 'Idea' : 'Ideas'}
        </span>
      </div>

      {!showSignInPrompt && (
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {shuffledIdeas.slice(0, ideasToShow).map((idea) => (
            <IdeaCard key={idea.id} idea={idea} />
          ))}
        </div>
      )}

      {showSignInPrompt && <SignInPrompt signInWithGoogle={signInWithGoogle} />}

      {!showSignInPrompt && ideasToShow < shuffledIdeas.length && (
        <div className="flex justify-center mt-6">
          <button className="btn btn-secondary" onClick={loadMoreIdeas}>
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default IdeasList;
