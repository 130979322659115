import React, { useEffect, useState } from 'react';
import { supabase } from './supabaseClient';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import the AuthProvider's useAuth hook
import Navbar from './Navbar';

const CreatorPreferences = () => {
  const navigate = useNavigate();
  const { session } = useAuth(); // Get the session from the AuthContext
  const userId = session?.user?.id; // Extract the userId from the session
  const [creators, setCreators] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!userId) return; // Avoid running queries if userId is not available

    const fetchCreatorsAndPreferences = async () => {
      try {
        // Fetch all creators
        const { data: creatorsData, error: creatorsError } = await supabase
          .from('channels')
          .select('id, name, profile_picture_url');
        if (creatorsError) throw creatorsError;

        setCreators(creatorsData);

        // Fetch user's current preferences
        const { data: preferencesData, error: preferencesError } = await supabase
          .from('user_creator_preferences')
          .select('creator_id')
          .eq('user_id', userId);
        if (preferencesError) throw preferencesError;

        // Set the selected creators based on preferences
        const selectedIds = preferencesData.map((pref) => pref.creator_id);
        setSelectedCreators(selectedIds);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCreatorsAndPreferences();
  }, [userId]);

  const toggleCreatorSelection = (creatorId) => {
    setSelectedCreators((prev) =>
      prev.includes(creatorId) ? prev.filter((id) => id !== creatorId) : [...prev, creatorId]
    );
  };

  const handleSubmit = async () => {
    if (!userId) {
      console.error('User ID is undefined. Cannot save preferences.');
      return;
    }

    setIsLoading(true);
    try {
      console.log('Selected Creators:', selectedCreators);

      // Prepare the preferences to insert
      const preferences = selectedCreators.map((creatorId) => ({
        user_id: userId,
        creator_id: creatorId,
      }));
      console.log('Prepared Preferences for Upsert:', preferences);

      // Insert or update preferences
      const { data: upsertData, error: insertError } = await supabase
        .from('user_creator_preferences')
        .upsert(preferences, { onConflict: ['user_id', 'creator_id'] });
      console.log('Upsert Response:', { upsertData, insertError });
      if (insertError) throw insertError;

      // Delete preferences for deselected creators
      const { data: deleteData, error: deleteError } = await supabase
        .from('user_creator_preferences')
        .delete()
        .not('creator_id', `in.(${selectedCreators.join(',')})`)
        .eq('user_id', userId);
      console.log('Delete Response:', { deleteData, deleteError });
      if (deleteError) throw deleteError;

      console.log('Preferences saved successfully. Navigating to home.');
      navigate('/');
    } catch (error) {
      console.error('Error updating preferences:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSkip = () => {
    navigate('/'); // Skip preferences and navigate to the root route
  };

  return (
    <div>
      <Navbar />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Select Your Favorite Creators</h1>
        <p className="text-gray-600 mb-6">
          Choose the creators you want to receive business idea newsletters from!
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {creators.map((creator) => (
            <div
              key={creator.id}
              className={`p-4 border rounded-lg cursor-pointer ${
                selectedCreators.includes(creator.id) ? 'border-blue-500 bg-blue-100' : ''
              }`}
              onClick={() => toggleCreatorSelection(creator.id)}
            >
              <div className="flex items-center">
                <img
                  src={creator.profile_picture_url || 'https://via.placeholder.com/50'}
                  alt={creator.name}
                  className="w-12 h-12 rounded-full mr-4"
                />
                <span className="font-medium">{creator.name}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-6 flex items-center justify-between">
          <button
            className="text-sm text-gray-500 underline hover:text-gray-700"
            onClick={handleSkip}
          >
            I don't want to receive any newsletters.
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={isLoading || selectedCreators.length === 0}
          >
            {isLoading ? 'Saving...' : 'Save Preferences'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatorPreferences;
