// HomePage.js
import React, { useState } from 'react';
import IdeasList from './IdeasList';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

import EmailCapture from './EmailCapture';

function HomePage({ channel }) {
  const { loading } = useAuth();

  if (loading) return <p>Loading...</p>;

  return (
    <div className="App">
      {/* Header */}
      <Navbar />

      {/* Conversion-Focused Email Capture Section */}
        {/* { (!session) &&
         (!emailSubmitted) ?
          <section className="container mx-auto p-4 mb-8 flex items-center justify-center">
            <EmailCapture setEmailSubmitted={setEmailSubmitted} message={message} setMessage={setMessage} />
          </section>
          : 
          <p className='flex items-center justify-center mt-12'>{message}</p>
        } */}

      {/* Main Content */}
      <main className="container mx-auto p-4">
        <IdeasList channel={channel} />
      </main>
    </div>
  );
}

export default HomePage;
