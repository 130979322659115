import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';

const OauthSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAccountAge = async () => {
      try {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) throw error;

        const accountCreatedAt = new Date(user.created_at);
        const currentTime = new Date();
        const timeDiffMinutes = (currentTime - accountCreatedAt) / (1000 * 60);

        console.log(`User created the account ${timeDiffMinutes} minutes ago!`);

        if (timeDiffMinutes <= 1) {
          navigate('/preferences'); // Redirect new users to preferences
        } else {
          navigate('/'); // Redirect older users to the home page
        }
      } catch (error) {
        console.error('Error checking account age:', error);
        navigate('/'); // Redirect to home on error
      }
    };

    checkAccountAge();
  }, [navigate]);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <div className="loader mb-4"></div>
        <p className="text-gray-600">Redirecting, please wait...</p>
      </div>
    </div>
  );
};

export default OauthSuccess;
