import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminPage from './AdminPage';
import HomePage from './HomePage';
import CreatorPreferences from './CreatorPreferences';
import { AuthProvider } from './AuthContext';
import Privacy from './Privacy';
import Terms from './Terms';
import Pricing from './Pricing';
import Unsubscribe from './Unsubscribe';
import OauthSuccess from './OauthSuccess';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />

          {/* Route for success */}
          <Route path="/success" element={<OauthSuccess />} />

          {/* Route for creator preferences */}
          <Route path="/preferences" element={<CreatorPreferences />} />

          {/* Routes for creators */}
          <Route path="/codie-sanchez" element={<HomePage channel="Codie Sanchez" />} />
          <Route path="/lennys-podcast" element={<HomePage channel="Lenny's Podcast" />} />
          <Route path="/noah-kagan" element={<HomePage channel="Noah Kagan" />} />
          <Route path="/simon-hoiberg" element={<HomePage channel="Simon Høiberg" />} />
          <Route path="/greg-isenberg" element={<HomePage channel="Greg Isenberg" />} />
          <Route path="/alex-hormozi" element={<HomePage channel="Alex Hormozi" />} />
          <Route path="/my-first-million" element={<HomePage channel="My First Million" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
