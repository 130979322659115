import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Navbar = () => {
  const { session, signInWithGoogle, logout } = useAuth();
  const navigate = useNavigate();

  return (
    <header className="flex justify-between items-center p-6 bg-gray-800 text-white">
      <h1
        className="text-xl font-bold cursor-pointer"
        onClick={() => navigate('/')}
      >
        📥IdeasInbound
      </h1>
      <div className="flex items-center space-x-4">
        {session ? (
          <div className="dropdown">
            <button className="btn btn-ghost">Account</button>
            <div className="dropdown-content p-2 bg-gray-200 rounded shadow-md">
              <p className="p-2 text-gray-800">{session.user.email}</p>
              <button
                className="btn btn-secondary btn-sm w-full mt-2"
                onClick={() => navigate('/preferences')}
              >
                Manage Newsletter Preferences
              </button>
              <button
                onClick={logout}
                className="btn btn-primary btn-sm w-full mt-2"
              >
                Sign Out
              </button>
            </div>
          </div>
        ) : (
          <button onClick={signInWithGoogle} className="btn btn-primary">
            Sign In with Google
          </button>
        )}
      </div>
    </header>
  );
};

export default Navbar;
